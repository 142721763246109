import React, { useContext } from 'react';
import { Empty } from 'antd';

import TenantMappingContext from '@totem/components/commissionReport/tenantMapping/tenantMappingContext';
import TenantMappingTable from '@totem/components/commissionReport/tenantMapping/tenantMappingTable';
import Loading from '@totem/components/Loading';
import { isNotNull } from '@totem/utilities/common';

const TenantMappingLayout = () => {
  const { filteredData, loading } = useContext(TenantMappingContext);
  const hasRmmData =
    isNotNull(filteredData) &&
    isNotNull(filteredData.rmm) &&
    filteredData.rmm.length > 0;
  const hasBackup =
    isNotNull(filteredData) &&
    isNotNull(filteredData.backup) &&
    filteredData.backup.length > 0;

  if (loading) {
    return <Loading />;
  }
  if (!hasBackup && !hasRmmData) {
    return <Empty />;
  }

  return (
    <>
      {hasRmmData && (
        <TenantMappingTable
          data={filteredData.rmm}
          requiredAction={'Map NinjaOne Tenant to Organization / Building'}
        />
      )}
      {hasBackup && (
        <TenantMappingTable
          data={filteredData.backup}
          requiredAction={
            'Map Cove or NinjaOne Tenant to Organization / Building'
          }
        />
      )}
    </>
  );
};

export default TenantMappingLayout;
