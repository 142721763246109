import React, { ReactNode, useContext, useEffect, useState } from 'react';

import TenantMappingContext from '@totem/components/commissionReport/tenantMapping/tenantMappingContext';
import {
  FilterOptions,
  TenantCommissionReport,
  TenantConnectionInput,
} from '@totem/components/commissionReport/tenantMapping/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull, isNull } from '@totem/utilities/common';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';
import { GetUniqueOrganizations } from '@totem/components/commissionReport/tenantMapping/utilities';
import { FilterStringValue } from '@totem/types/devices';
import UserProfileContext from '@totem/components/UserProfileContext';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  children?: ReactNode;
};

const TenantMappingContainer = ({ children }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reportData, setReportData] = useState<TenantCommissionReport>(null);
  const [filteredData, setFilteredData] = useState<TenantCommissionReport>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({ organization: []})
  const [input, updateInput] = useState<TenantConnectionInput>({ organizationId: [userProfile.organization.id] })

  const setInput = (updated: Partial<TenantConnectionInput>) => {
    updateInput((prevInput) => {
      const newInput = { ...prevInput, ...updated };
      return newInput;
    });
    setRefreshData(true);
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      fetch(`${CONTRACTS_ENDPOINT}/reconciliation/missingTenantMappings`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: TenantCommissionReport) => {
          setReportData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  useEffect(() => {
    if (isNotNull(reportData)) {
      const uniqueOrgs = GetUniqueOrganizations([...reportData.backup, ...reportData.rmm]);
      const orgOptions: FilterStringValue[] = uniqueOrgs.map((chk) => { return {name: chk.name, value: chk.id}});
      setFilterOptions((prevState) => ({ ...prevState, organization: orgOptions}));

      let filteredData = reportData;
      if (isNotNull(input)) {
        if (isNotNull(input.organizationId) && input.organizationId.length > 0) {
          filteredData.backup = reportData.backup.filter((chk) => input.organizationId.includes(chk.organization.id));
          filteredData.rmm = reportData.rmm.filter((chk) => input.organizationId.includes(chk.organization.id));
        }
      }
      setFilteredData(filteredData);
    }
  }, [reportData, input]);

  return (
    <TenantMappingContext.Provider
      value={{
        reportData,
        filteredData,
        filterOptions,
        input,
        setInput,
        loading: isLoading,
        totalRecords: isNull(reportData)
          ? 0
          : reportData.rmm.length + reportData.backup.length,
      }}
    >
      {children}
    </TenantMappingContext.Provider>
  );
};

export default TenantMappingContainer;
