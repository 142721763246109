import React, { useContext, useState } from 'react';
import { Badge, Tabs, TabsProps } from 'antd';

import BackupNotOnContractTable from '@totem/components/commissionReport/BackupNotOnContractTable';
import CommissioningReportContainer from '@totem/components/commissionReport/CommissioningReportContainer';
import LinesWithoutDevicesTable from '@totem/components/commissionReport/LinesWithoutDevicesTable';
import TenantMappingContainer from '@totem/components/commissionReport/tenantMapping/tenantMappingContainer';
import TenantMappingLayout from '@totem/components/commissionReport/tenantMapping/tenantMappingLayout';
import DeviceEmbeddedContainer from '@totem/components/devices/devicesContainer/DeviceEmbeddedContainer';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

const CommissioningReport = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [
    servicesNotDeliveredTotalRecords,
    setServicesNotDeliveredTotalRecords,
  ] = useState<number>(0);
  const [backupTotalRecords, setBackupTotalRecords] = useState<number>(0);
  const [rmmTotalRecords, setRMMTotalRecords] = useState<number>(0);
  const [remoteAccessTotalRecords, setRemoteAccessTotalRecords] =
    useState<number>(0);
  const [antiVirusTotalRecords, setAntiVirusTotalRecords] = useState<number>(0);
  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'contract-lines-not-deployed',
        label: (
          <Badge
            count={servicesNotDeliveredTotalRecords}
            overflowCount={9999}
            offset={[0, -7]}
          >
            <TabTitle>Service Not Delivered</TabTitle>
          </Badge>
        ),
        children: <LinesWithoutDevicesTable />,
      },
      {
        key: 'backups-not-contracted',
        label: (
          <Badge
            count={backupTotalRecords}
            overflowCount={9999}
            offset={[0, -7]}
          >
            <TabTitle>Backups Not On Contract</TabTitle>
          </Badge>
        ),
        children: (
          <DeviceEmbeddedContainer
            defaultFilters={{ organizationId: [userProfile.organization.id] }}
            deviceEndPoint={`${DEVICES_ENDPOINT}/backupsNotOnContract`}
            onRecordTotalChanged={setBackupTotalRecords}
          >
            <BackupNotOnContractTable />
          </DeviceEmbeddedContainer>
        ),
      },
      {
        key: 'rmm-not-contracted',
        label: (
          <Badge count={rmmTotalRecords} overflowCount={9999} offset={[0, -7]}>
            <TabTitle>RMM Not On Contract</TabTitle>
          </Badge>
        ),
        children: (
          <DeviceEmbeddedContainer
            defaultFilters={{ organizationId: [userProfile.organization.id] }}
            deviceEndPoint={`${DEVICES_ENDPOINT}/rmmNotOnContract`}
            onRecordTotalChanged={setRMMTotalRecords}
          >
            <BackupNotOnContractTable />
          </DeviceEmbeddedContainer>
        ),
      },
      {
        key: 'remote-access-not-contracted',
        label: (
          <Badge
            count={remoteAccessTotalRecords}
            overflowCount={9999}
            offset={[0, -7]}
          >
            <TabTitle>Remote Access Not On Contract</TabTitle>
          </Badge>
        ),
        children: (
          <DeviceEmbeddedContainer
            defaultFilters={{ organizationId: [userProfile.organization.id] }}
            deviceEndPoint={`${DEVICES_ENDPOINT}/remoteAccessNotOnContract`}
            onRecordTotalChanged={setRemoteAccessTotalRecords}
          >
            <BackupNotOnContractTable />
          </DeviceEmbeddedContainer>
        ),
      },
      {
        key: 'anti-virus-not-contracted',
        label: (
          <Badge
            count={antiVirusTotalRecords}
            overflowCount={9999}
            offset={[0, -7]}
          >
            <TabTitle>Anti-Virus Not On Contract</TabTitle>
          </Badge>
        ),
        children: (
          <DeviceEmbeddedContainer
            defaultFilters={{ organizationId: [userProfile.organization.id] }}
            deviceEndPoint={`${DEVICES_ENDPOINT}/antiVirusNotOnContract`}
            onRecordTotalChanged={setAntiVirusTotalRecords}
          >
            <BackupNotOnContractTable />
          </DeviceEmbeddedContainer>
        ),
      },
      {
        key: 'missing-tenant-mappings',
        label: <TabTitle>Missing Tenant Mappings</TabTitle>,
        children: (
          <TenantMappingContainer>
            <TenantMappingLayout />
          </TenantMappingContainer>
        ),
      },
    ];

    return tabItems;
  };

  return (
    <CommissioningReportContainer
      onRecordTotalChanged={setServicesNotDeliveredTotalRecords}
    >
      <Tabs defaultActiveKey="Users" items={getTabItems()} />
    </CommissioningReportContainer>
  );
};

export default CommissioningReport;
