import React, { useContext } from 'react';
import { ColumnProps, TablePaginationConfig } from 'antd/es/table';

import TenantMappingContext from '@totem/components/commissionReport/tenantMapping/tenantMappingContext';
import { TenantCommissionReportEntry } from '@totem/components/commissionReport/tenantMapping/types';
import Table from '@totem/components/common/table/Table';
import { isNotNull } from '@totem/utilities/common';
import { DateStringToLocalDate } from '@totem/utilities/dateUtilities';

import '../commissionReport.css';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';
import { SorterResult } from 'antd/lib/table/interface';

type Props = {
  requiredAction: string;
  data: TenantCommissionReportEntry[];
};

const TenantMappingTable = ({ requiredAction, data }: Props) => {
  const { loading, input, setInput, filterOptions } = useContext(TenantMappingContext);

  const getBuildingDisplay = (record: TenantCommissionReportEntry) => {
    if (record.building.name !== null && record.building.name !== '') {
      return <span>{record.building.name}</span>;
    }

    return <span styleName="actionRequired">BUILDING NOT ASSIGNED</span>;
  };

  const getServiceDisplay = (record: TenantCommissionReportEntry) => {
    return (
      <span>
        {record.contractLine.services.map((svc) => (
          <span key={svc.id}>
            {svc.name}
            <br />
          </span>
        ))}
      </span>
    );
  };

  const getLineNumberDisplay = (record: TenantCommissionReportEntry) => {
    let serviceOrderNumber = '';
    for (let idx = 0; idx < record.contractLine.keys.length; idx++) {
      const lineKey = record.contractLine.keys[idx];
      if (
        lineKey.system === 'NetSuite' &&
        lineKey.instance === 'NETSUITE_SALES_ORDER_NUMBER'
      ) {
        serviceOrderNumber = lineKey.key;
      }
    }

    return (
      <span>
        {serviceOrderNumber}: {record.contractLine.number}
      </span>
    );
  };

  const getStartDate = (record: TenantCommissionReportEntry) => {
    for (let idx = 0; idx < record.contractLine.keys.length; idx++) {
      const key = record.contractLine.keys[idx];
      if (key.instance === 'IB_CONTRACT_LINE_KEY') {
        return (
          <span key={`ts${key.instance}`}>
            {DateStringToLocalDate(key.createdAt)}
          </span>
        );
      }
    }

    return <span />;
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<TenantCommissionReportEntry>,
    sorter,
  ) => {
    const { ...params } = filters;
    setInput({
      ...input,
      ...params,
    });
  }

  const columns: ColumnProps<TenantCommissionReportEntry>[] = [
    {
      title: 'Organization',
      dataIndex: 'organization.name',
      key: 'organizationId',
      render: (_, record: TenantCommissionReportEntry) => (
        <span>{record.organization.name}</span>
      ),
      filterMultiple: true,
      filteredValue: stringifyArray(input.organizationId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.organization
          : null,
      ),
    },
    {
      title: 'Building',
      dataIndex: 'contract.billingContainers[0].lines[0].serviceAddress.name',
      key: 'buildingId',
      render: (_, record: TenantCommissionReportEntry) =>
        getBuildingDisplay(record),
    },
    {
      title: 'Services',
      dataIndex: 'contract.billingContainers[0].lines[0].id',
      key: 'serviceLineId',
      render: (_, record: TenantCommissionReportEntry) =>
        getServiceDisplay(record),
    },
    {
      title: 'Line Number',
      dataIndex: 'contract.billingContainers[0].lines[0].id',
      key: 'lineNumber',
      render: (_, record: TenantCommissionReportEntry) =>
        getLineNumberDisplay(record),
    },
    {
      title: 'Status',
      dataIndex: 'contract.billingContainers[0].lines[0].status',
      key: 'status',
      render: (_, record: TenantCommissionReportEntry) => (
        <span>{record.contractLine.status}</span>
      ),
    },
    {
      title: 'Required Action',
      dataIndex: 'requiredAction',
      key: 'requiredAction',
      render: () => <span>{requiredAction}</span>,
    },
    {
      title: 'Start Date',
      dataIndex: 'ticket.startDate',
      key: 'ticketStartDate',
      render: (_, record: TenantCommissionReportEntry) => getStartDate(record),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(data) ? data : []}
      onChange={handleTableChange}
      loading={loading}
      rowKey={(record) => record.contractLine.id}
      pagination={{
        showSizeChanger: true,
      }}
    />
  );
};

export default TenantMappingTable;
