import { TenantCommissionReportEntry, ObjectReference } from '@totem/components/commissionReport/tenantMapping/types';

export function GetUniqueOrganizations(
  entries: TenantCommissionReportEntry[]
): ObjectReference[] {
  const orgMap = new Map<string, ObjectReference>();

  for (const entry of entries) {
    const org = entry.organization;
    if (!orgMap.has(org.id)) {
      orgMap.set(org.id, org);
    }
  }

  return Array.from(orgMap.values());
}
