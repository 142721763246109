import { createContext } from 'react';

import {
  FilterOptions,
  TenantCommissionReport,
  TenantConnectionInput,
} from '@totem/components/commissionReport/tenantMapping/types';

interface Context {
  loading: boolean;
  totalRecords: number;
  reportData: TenantCommissionReport;
  filteredData: TenantCommissionReport;
  filterOptions: FilterOptions;
  input: TenantConnectionInput;
  setInput: (input: TenantConnectionInput) => void;
}

export default createContext<Context>({
  loading: false,
  totalRecords: 0,
  reportData: null,
  filteredData: null,
  filterOptions: {
    organization: [],
  },
  input: {},
  setInput: () => {},
});
