import React, { useContext, useEffect, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { Contact, SupportLine } from '@totem/components/supportLines/types';
import SupportLinesContext from '@totem/components/supportLines/supportLinesContext';
import { formatDateOnly, isNotNull } from '@totem/utilities/common';
import Table from '@totem/components/common/table/Table';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
import TableTitle from '@totem/components/controlSystemReport/TableTitle';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import SupportLineEditDialog from '@totem/components/supportLines/supportLineEditDialog';

const styles = {
  button: {
    marginRight: '4px',
  },
};

const SupportLinesTable = () => {
  const { data, loading, onAction } = useContext(SupportLinesContext);
  const [lines, setLines] = useState<SupportLine[]>(isNotNull(data) && isNotNull(data.supportLines) ? data.supportLines : []);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedLine, setSelectedLine] = useState<SupportLine>();

  useEffect(() => {
    if (isNotNull(data) && isNotNull(data.supportLines)) {
      setLines(data.supportLines);
      return;
    }
    setLines([]);
  }, [data]);

  const getContactDisplay = (contact: Contact) => {
    return (
      <div>
        {isNotNull(contact.name) && contact.name != '' && (
          <div>{contact.name}</div>
        )}
        {isNotNull(contact.name) && contact.name != '' && (
          <div>{contact.phone}</div>
        )}
        {isNotNull(contact.name) && contact.name != '' && (
          <div><a href={'mailto:' + contact.email}>{contact.email}</a></div>
        )}
      </div>
    );
  };

  const showEditDialog = (selectedLine: SupportLine) => {
    setSelectedLine(selectedLine);
    setShowDialog(true);
  };

  const handleClose = (refresh: boolean) => {
    setShowDialog(false);
    if (refresh) {
      onAction('refresh', null);
    }
  }

  const columns: ColumnProps<SupportLine>[] = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (_, record: SupportLine) => record.companyName,
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.companyName,
          compB.companyName,
        ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      showSorterTooltip: true,
      render: (_, record: SupportLine) =>
        formatDateOnly(record.startDate),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.startDate,
          compB.startDate,
        ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      showSorterTooltip: true,
      render: (_, record: SupportLine) =>
        formatDateOnly(record.endDate),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.endDate,
          compB.endDate,
        ),
    },
    {
      title: 'Auto-Renewable',
      dataIndex: 'autoRenewable',
      key: 'autoRenewable',
      showSorterTooltip: true,
      render: (_, record: SupportLine) =>
        record.autoRenewable.toString(),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.autoRenewable.toString(),
          compB.autoRenewable.toString(),
        ),
    },
    {
      title: 'Contract Contact',
      dataIndex: 'contractContact.name',
      key: 'contractContact.name',
      showSorterTooltip: true,
      render: (_, record: SupportLine) => getContactDisplay(record.contractContact),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.contractContact.name,
          compB.contractContact.name,
        ),
    },
    {
      title: 'Support Contact',
      dataIndex: 'supportContact.name',
      key: 'supportContact.name',
      showSorterTooltip: true,
      render: (_, record: SupportLine) => getContactDisplay(record.supportContact),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.supportContact.name,
          compB.supportContact.name,
        ),
    },
    {
      title: 'SLA',
      dataIndex: 'serviceLevelAgreement',
      key: 'serviceLevelAgreement',
      render: (_, record: SupportLine) => record.serviceLevelAgreement,
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.serviceLevelAgreement,
          compB.serviceLevelAgreement,
        ),
    },
    {
      title: <TableTitle>Actions</TableTitle>,
      dataIndex: '',
      width: '84px',
      render: (_, record: SupportLine) => (
        <div className="center-table-cell">
          <Tooltip title="Edit" placement="top">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              style={styles.button}
              onClick={() => showEditDialog(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        showSorterTooltip
        columns={columns}
        dataSource={lines}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={{
          total: lines.length,
          showSizeChanger: true,
        }}
      />
      { showDialog && (
        <SupportLineEditDialog owner={data} supportLine={selectedLine} onClose={handleClose} visible={showDialog} />
      )}
    </>
  );
};

export default SupportLinesTable;
